import React from "react"
import { Row, Col, Image, Button, Accordion } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

import rapid from "../assets/pictures/dynamic/rapidweb.png"
import video from "../assets/pictures/dynamic/rapidwebfinalvideo.mp4"
import slide from "../assets/pictures/dynamic/slide.png"
import dia1 from "../assets/pictures/dynamic/diagram1.png"
import dia2 from "../assets/pictures/dynamic/diagram2.png"
import dia3 from "../assets/pictures/dynamic/dia3.png"

const Dynamic = () => (
  <Layout>
    <div style={{ height: "90vh", overflowY: "scroll" }}>
    <SEO title="Dynamic Website Studio" />
    <div className="jumbotron" style={{ margin: "7.5vh 10vh 0vh" }}>
      <h1 className="title">Dynamic Website Builder</h1>
      <p className="text" style={{backgroundColor: "white"}}>
        In Fall 2020, I took a class called Senior Team where in a semester I would be working
        with a team of three people to complete a project proposed by a sponsor. In the team I was 
        assigned to, we were to create a web-based application called Rapid Website Creation Studio. 
        The goal of the project was to build a website creator, similar to SquareSpace or Wordpress, that enables
        non-technical content desginers to create and maintain a web experience that is dynamic and highly adaptive
        to the web visitors' intent and onsite behavior.

        <br/><br/>

        The previous year in Fall 2019, the Senior project Behavior Based Dynamic Website Generator 
        focused on the backend implementation of assembling dynamic web pages based on tagged content.

        <br/><br/>

        The Rapid Website Creation Studio project was to design an interactive web application that assists and guides non-technical 
        users to generate and maintain HTML content fragments that can be previewed simulating different user intent (interrested, engaged, confused). 
        The focus was to have a simple but powerful workflow that guides users through the web content creation process.

        <br/><br/>
        The project sponsor was Roger Ruttimann CTO at Reactful Inc. responsible for Technology Vision, 
        Product Development, Operations and Technology Partner Relations.
      </p>
      <Image src={slide} fluid style={{margin: "auto", display: "block"}}/>
    </div>
    <div
      className="jumbotron"
      style={{ margin: "0vh 10vh 7.5vh 10vh" }}
    >
      <p className="subtitle">Previous Projects</p>
      <p className="subtitle" style={{fontSize: "1.8rem"}}>Backend Implementation - Behavior Based Dynamic Website Generator</p>
      <Row>
        <Col>
          <p className="text" style={{backgroundColor: "white"}}>
          In Fall 2019, the previous team, Behavior-Based Dynamic Website
          Generator, designed a new paradigm for designing websites that can
          dynamically generate content based on user behavior and digital body 
          language, which is powered by Reactful's machine learning algorithms.

          <br/><br/>
          To make webpage content dynamic, we have to fragmentize the webpage into 
          independent(closed) unit of content. Then, we can deterministically or 
          randomly assemble these fragments into the full webpage.

          <br/><br/>
          A <span style={{fontWeight: "bold"}}>Fragment</span> is another layer of abstraction of a specific collection of nodes 
          in the DOM Tree.
          </p>
        </Col>

        <Col>
          <Image src={dia2} style={{marginTop: "1rem", marginBottom:"1rem"}} fluid />
        </Col>
      </Row>
      
      <Row>
        <Col md="auto"><Image src={dia1} fluid /></Col>
        <Col>
          <p className="text" style={{backgroundColor: "white"}}>
            A fragment begins with an HTML element/tag that specifies the condition of its 
            appearance, followed by some explicit/rigid HTML structures, and may contains one
            or many flexible Joints that other fragments can be attached under.
          </p>
        </Col>
        <Col md="auto"><Image src={dia3} fluid /></Col>
      </Row>

      <p className="text" style={{backgroundColor: "white", marginBottom: "10px"}}>
        <br/>
        A <span style={{fontWeight: "bold"}}>Joint</span> is also an HTML element/tag that specifies what type of fragments are compatible 
        under this joint and this joint's capacity. 
      </p>

      <Row>
        <Col style={{paddingTop: "3rem"}}>
          <pre style={{backgroundColor: "black", color: "white", fontWeight: "bold"}}>
            &lt;div class="panel-content" data-id="5" data-label="label1,label2" data-page="pg1,pg2"&gt;
            <br/>
            <span style={{marginLeft: "2em"}}>&lt;i class="la la-tag panel-icon" /&gt;</span>
            <br/>
            <span style={{marginLeft: "2em"}}>&lt;div&gt;Loyalty Benefits&lt;/div&gt;</span>
            <br/>
            &lt;/div&gt;
          </pre>
        </Col>

        <Col>
          <p className="text" style={{backgroundColor: "white"}}>
          In this example, the outer div tag is the beginning boundary of the fragment,
          which also contains data-label, data-page, data-template that specify under what page, label,
          and template can this fragment appear.

          <br/><br/>
          The body of the fragment contains an &lt;i&gt; tag and a &lt;div&gt; tagged

          <br/><br/>
          This fragment does not have attachment joints.
          </p>
        </Col>
      </Row>
  
      <p className="subtitle" style={{fontSize: "1.8rem"}}>Fragment Dependencies</p>
      <p className="text" style={{backgroundColor: "white"}}>
        Once we have the Fragments of a webpage, we cannot simply assemble them directly since many 
        fragments have dependencies that restrict where this fragment can appear for asthetic purpose.
        <br/><br/>

        For example, say we have many fragments that appear as individual products on an ecommerce website.
        These fragments cannot appear in any location of the webpage. They can only appear in specific departments,
        categories, or subsection fragments.
        <br/><br/>

        In this case, the two attributes specified on Joints are used to enforce this contraint:
        <br/><br/>

        <span style={{marginLeft: "2rem"}}>1. <span style={{fontWeight: "bold"}}>data-child-type</span>: what fragments are allowed to be under this joint.</span>
        <br/><br/>

        Specifically, the value in this comma-separated list can be both the class and id attribute of HTML elements.
        By using class names, we can directly refer to a collection of fragments with the same class.
        <br/><br/>
      
        <span style={{marginLeft: "2rem"}}>2. <span style={{fontWeight: "bold"}}>data-child-limit</span>: despite the types of fragments selected, how many fragments can fit within this joint.</span>
        <br/><br/>

        This is necessary for asthetic purpose as well since we cannot fit too many products in a department section.
      </p>

      <Row>
        <Col>
          <p className="text" style={{backgroundColor: "white", paddingTop: "1rem"}}>
            To demonstrate the example above, we first define a container fragment that has a single joint of capacity 3:
          </p>
        </Col>

        <Col>
          <pre style={{backgroundColor: "black", color: "white", fontWeight: "bold"}}>
            &lt;div class="department" data-page="electronics" data-template="tp1" data-label="engage"&gt;
            <br/>
            <span style={{marginLeft: "2em"}}>&lt;h3&gt; Products in electronics department &lt;/h3&gt;</span>
            <br/>
            <span style={{marginLeft: "2em"}}>&lt;div class="product-container" data-child-type="product, christmas-special"&lt;/div&gt;</span>
            <br/>
            &lt;/div&gt;
          </pre>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="text" style={{backgroundColor: "white", paddingTop: "2rem"}}>
            Then, we can define multiple products:
            <br/><br/>

            Although the third fragment does not belong to product class, since the id christmas-special is explicitly
            used in the departments joint, all three fragments will appear under the department fragment.
          </p>
        </Col>

        <Col>
          <pre style={{backgroundColor: "black", color: "white", fontWeight: "bold"}}>
            &lt;div class="product"&gt;
            <br/>
            <span style={{marginLeft: "2em"}}>&lt;img&gt;...&lt;/img&gt;</span>
            <br/>
            &lt;/div&gt;
            <br/>
            &lt;div class="product"&gt;
            <br/>
            <span style={{marginLeft: "2em"}}>&lt;img&gt;...&lt;/img&gt;</span>
            <br/>
            &lt;/div&gt;
            <br/>
            &lt;div class="special-product" id="christmas-special"&gt;
            <br/>
            <span style={{marginLeft: "2em"}}>&lt;img&gt;...&lt;/img&gt;</span>
            <br/>
            &lt;/div&gt;
          </pre>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="text" style={{backgroundColor: "white", paddingTop: "2rem"}}>
            Son the final asembled page would look like:
          </p>
        </Col>

        <Col>
          <pre style={{backgroundColor: "black", color: "white", fontWeight: "bold"}}>
            &lt;div class="department"&gt;
            <br/>
            <span style={{marginLeft: "2em"}}>&lt;h3&gt; Products in electronics department &lt;/h3&gt;</span>
            <br/>
            <span style={{marginLeft: "2em"}}>&lt;div class="product-container"&gt;</span>
            <br/>
            <span style={{marginLeft: "4em"}}>&lt;div class="product"&gt;</span>
            <br/>
            <span style={{marginLeft: "6em"}}>&lt;img&gt;...&lt;/img&gt;</span>
            <br/>
            <span style={{marginLeft: "4em"}}>&lt;/div&gt;</span>
            <br/>
            <span style={{marginLeft: "4em"}}>&lt;div class="product"&gt;</span>
            <br/>
            <span style={{marginLeft: "6em"}}>&lt;img&gt;...&lt;/img&gt;</span>
            <br/>
            <span style={{marginLeft: "4em"}}>&lt;/div&gt;</span>
            <br/>
            <span style={{marginLeft: "4em"}}>div class="special-product" id="christmas-special"</span>
            <br/>
            <span style={{marginLeft: "6em"}}>&lt;img&gt;...&lt;/img&gt;</span>
            <br/>
            <span style={{marginLeft: "4em"}}>&lt;/div&gt;</span>
            <br/>
            <span style={{marginLeft: "2em"}}>&lt;/div&gt;</span>
            <br/>
            &lt;/div&gt;
          </pre>
        </Col>
      </Row>

      <p className="subtitle" style={{fontSize: "1.8rem"}}>Fragment Assembly Logic</p>
      <p className="text" style={{backgroundColor: "white"}}>
        To explain how the assembler works, we first have to clarify <span style={{fontWeight: "bold"}}>Templates</span>.
        <br/><br/>

        <span style={{fontWeight: "bold"}}>Templates</span> are a special type of fragment that does not have the attribute data-template in its
        root tag. Though they can still have riqid bodies and joints
        <br/><br/>

        The only difference between <span style={{fontWeight: "bold"}}>Templates</span> and regular <span style={{fontWeight: "bold"}}>Fragment</span>
         is that templates are tracked by the assembler to use as the top level fragment. In other words, the assembly process begins by selecting one out of 
        many templates.
        <br/><br/>

        Usually, the templates are the top-level structures of the webpage. They describes the overall layout of the webpage, which includes some rigid structures
        such as a navigation bar, header, and footer, but also contains sections allocated for dynamic content(Joints).
        <br/><br/>

        Once an assembly request is received, we first select a template that is best suited for the given label and page. For example: template A can appear in label A, B, C,
        while template B can appear in label B. If the input labels are label B, C, template A will be selected since template A has two matches while template B only has one.
        <br/><br/>

        Once a template is selected, the assembly engine traverses all joints in the template and looks for suitable fragments to attach. If a joint allows class A, B, C to be attached,
        fragments from the union of all three sets are allowed to attach. If a fragment of class A's data-page, data-label, data-template is incompatible with the input page, label, and
        our selected template, it is not considered to be attached.
        <br/><br/>

        Once a fragment is attached, we recursively assembly any other joints within that fragment. So the overall time complexity depends of the depth of nested fragments and the overall
        complexities of the fragments. By recursively exhaust all possible joints, or maxed out the joint capacity, we have assembled the final HTML DOM tree.
      </p>

      <p className="subtitle" style={{fontSize: "1.8rem"}}>API Neon Juice</p>
      <p className="text" style={{backgroundColor: "white"}}>
        
      </p>
      <Row>
        <Col>
          <p className="subtitle" style={{fontSize: "1.5rem"}}>Fragments - CRUD operations on fragments</p>
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>GET /upload</Accordion.Header>
              <Accordion.Body>
                Upload the fragment archive and deploy new fragments
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>GET /fragments</Accordion.Header>
              <Accordion.Body>
                List all fragments
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>POST /fragments</Accordion.Header>
              <Accordion.Body>
                Submit a new fragment
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>GET /fragments/&#123;fragment-id&#125;</Accordion.Header>
              <Accordion.Body>
                Get fragment by id
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>PUT /fragments/&#123;fragment-id&#125;</Accordion.Header>
              <Accordion.Body>
                Update a fragment with new HTML
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>DELETE /fragments/&#123;fragment-id&#125;</Accordion.Header>
              <Accordion.Body>
                Update a fragment
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <p className="subtitle" style={{fontSize: "1.5rem", marginTop: "1rem"}}>Assembler Configurations</p>
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>GET /&#123;page&#125;</Accordion.Header>
              <Accordion.Body>
                Build a page
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>GET /assembler/statistics</Accordion.Header>
              <Accordion.Body>
                View statistics of assembler
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>GET /assembler/templates</Accordion.Header>
              <Accordion.Body>
                List all templates
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>GET /assembler/pages</Accordion.Header>
              <Accordion.Body>
                List all pages
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>GET /assembler/templates/&#123;template-name&#125;/activate</Accordion.Header>
              <Accordion.Body>
                Activate given template
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>GET /assembler/templates/&#123;template-name&#125;/deactivate</Accordion.Header>
              <Accordion.Body>
                Deactivate given template
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>GET /assembler/pages/&#123;page-name&#125;/activate</Accordion.Header>
              <Accordion.Body>
                Activate given page
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>GET /assembler/pages/&#123;page-name&#125;/deactivate</Accordion.Header>
              <Accordion.Body>
                Deactivate given page
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>

        <Col>
          <p className="text" style={{backgroundColor: "white", margin: "10vh"}}>
            <span className="subtitle" style={{fontSize: "1.5rem", marginBottom: "1rem"}}>Schemas</span>
            <br/><br/>

            Fragment &#123;
            <table style={{margin: "2vw"}}>
              <tr>
                <td>id*</td>
                <td>integer($uuid)</td>
              </tr>
              <tr>
                <td>id_attr*</td>
                <td>string<br/>example: HTMLTAG_ID</td>
              </tr>
              <tr>
                <td>class_attr*</td>
                <td>string<br/>example: panel-special-main</td>
              </tr>
              <tr>
                <td>html*</td>
                <td>string<br/>example: &lt;div&gt;&lt;/div&gt;</td>
              </tr>
              <tr>
                <td>joints*</td>
                <td>[Joint...]</td>
              </tr>
              <tr>
                <td>labels</td>
                <td>[string <br/>example: lb1]</td>
              </tr>
              <tr>
                <td>templates</td>
                <td>[string <br/>example:tp1]</td>
              </tr>
              <tr>
                <td>pages</td>
                <td>[string <br/>example: back-to-school]</td>
              </tr>
              <tr>
                <td>file-name</td>
                <td>string<br/>example: panel-main.html</td>
              </tr>
            </table>
            &#125;
            <br/><br/>

            Joint &#123;
            <table style={{margin: "2vw"}}>
              <tr>
                <td>child_types</td>
                <td>[string<br/>example:panel-special-main-items]</td>
              </tr>
              <tr>
                <td>limit</td>
                <td>integer<br/>example: 10</td>
              </tr>
            </table>
          </p>
        </Col>
      </Row>
      
      <p className="subtitle" style={{fontSize: "1.8rem"}}>Frontend Implementation - Rapid Website Creation Studio</p>
      
      <Image src={rapid} fluid />
      <Row className="justify-content-md-center" style={{marginTop: "5vh"}}>
        <Col style={{textAlign: "center"}}>
          <p className="text" style={{marginTop: "15vh", backgroundColor: "white"}}>
            Here is a video demo of the project at the end of Senior Project<br/> and Github repo were the API and two previous 
            iterations of the frontend implementations are located.
          </p>
          <Button href="https://github.com/captech-partners/neon-juice/tree/master/assembler/rapid-web" variant="primary" size="lg">
            See Original Project Repo
          </Button>{' '}
        </Col>
        <Col>
        <video src={video} width="750" height="500" controls>
        </video>
        </Col>
      </Row>
    </div>
    <div
      className="jumbotron"
      style={{ margin: "7.5vh 10vh 7.5vh 10vh" }}
    >
      <p className="text" style={{backgroundColor: "white"}}>
        Unfortunately after revisiting the project a year and a half later, some of the node packages were outdated and stopped this code from being functional.
        Since there is a lot of potential with the concept and the Neon Juice API, I have restarted from scratch and decided to create a new and improved version of
        the Rapid Website Creation Studio project.
        <br/>
        
      </p>
      <Row className="justify-content-md-center">
        <Col sm="auto">
        <Button href="https://angry-poincare-9773d7.netlify.app/" variant="primary" size="lg">
          See New Project
        </Button>{' '}
        </Col>
      </Row>
    </div>
    </div>
  </Layout>
)

export default Dynamic
